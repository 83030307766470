// material-ui
import React from "react";
import { useDispatch, useSelector } from "store";
//import { useTheme } from "@mui/material/styles";

import { getWebsiteSetting } from "store/slices/user";
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = (propsData) => {
  //const theme = useTheme();
  const dispatch = useDispatch();
  const { websiteSetting } = useSelector((state) => state.user);
  const [previewImage, setPreviewImage] = React.useState();
  const [data, setData] = React.useState();

  React.useEffect(() => {
    setData(propsData.width);
  }, [propsData.width]);

  React.useEffect(() => {
    if (websiteSetting?.length > 0) {
      setPreviewImage("https://api.24xbet.co" + websiteSetting[0]?.logoUrl);
    }
  }, [websiteSetting]);

  React.useEffect(() => {
    dispatch(getWebsiteSetting());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>{previewImage && <img src={previewImage} alt="web" width={data} />}</>
  );
};

export default Logo;
